































































import DataProvider from "@/lib/DataProvider";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import Columns from "@/data/columns/MiniCalendar";
import InMemoryDataProvider from "@/lib/InMemory/InMemoryDataProvider";
import { saveAs } from "file-saver";
import DatePicker from "@/components/DatePicker.vue";
@Component({
  components: {
    ModelTable,
    DatePicker
  }
})
export default class MiniCalendarTable extends Vue {
  public provider: DataProvider<any> | null = null;
  private columns = Columns;
  private rows: any = null;
  private allAppointments = false;
  private prevAppointments = false;
  private filter = {
    date: null,
    teamAppointments: false,
    allAppointments: true
  };

  async mounted() {
    this.getAppointments();
  }

  async extract() {
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(
      await this.$service.extract("RequestSubmissions/Extract", this.filter),
      `${this.$auth.userInfo.profile.firstName} ${this.$auth.userInfo.profile.lastName}_Appointments_${utc}.xlsx`
    );
  }

  private async getAppointments() {
    if (this.filter.allAppointments) {
      this.filter.date = null;
    }

    const items = (await this.$service.getScheduledAppointments(this.filter))
      .data;
    this.rows = items;
    this.provider = new InMemoryDataProvider(items);
  }
}
