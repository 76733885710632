export default [
  {
    name: "beneficiary",
    required: true,
    label: "Beneficiary",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.beneficiary
  },
  {
    name: "appointmentDate",
    required: true,
    label: "Date",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) =>
      i.appointmentDate ? new Date(Date.parse(i.appointmentDate)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "age",
    required: true,
    label: "Age",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.age
  },
  {
    name: "gender",
    required: true,
    label: "Gender",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.gender
  },
  {
    name: "legalStatus",
    required: true,
    label: "Legal Status",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.legalStatus
  },
  {
    name: "caseStatus",
    required: true,
    label: "Case Status",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.caseStatus
  },
  {
    name: "caseWorker",
    required: true,
    label: "Case Worker",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.caseWorker
  },
  {
    name: "projectTitle",
    required: true,
    label: "Project Title",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.projectTitle
  },
  {
    name: "serviceType",
    required: true,
    label: "Service Type",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.serviceType
  },
  {
    name: "request",
    required: true,
    label: "Request",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.request
  },
  {
    name: "requestName",
    required: true,
    label: "Request Name",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.requestName
  },
  {
    name: "requestStatus",
    required: true,
    label: "Request Status",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.requestStatus
  },
  {
    name: "nextStep",
    required: true,
    label: "Next Step",
    align: "left",
    sortable: true,
    headerClasses: "bg-primary text-white",
    field: (i: any) => i.nextStep
  }
];
